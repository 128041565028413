.toast {
    .alert {
        width: 320px;
        position: relative;
        white-space: initial;

        .clear-btn {
            position: absolute;
            top: 0px;
            right: 0px;
            margin: 0px;
        }
    }
}