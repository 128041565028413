@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --btn-text-case: none
}

@layer base {
    html {
        font-size: 1.1rem;
    }
}