.app {
    position: relative;

    .corner {
        width: 0;
        height: 0;
        border-top: 50px solid hsl(var(--er));

        border-right: 50px solid transparent;
        position: absolute;
        left: 0px;
        top: 0px;

        .text {
            position: absolute;
            transform: rotate(-45deg);
            left: 3px;
            top: -42px;
            font-size: 0.7rem;
            color: #fff;
        }

        &.info {
            border-top: 50px solid hsl(var(--in));

            .text {
                top: -40px;
                left: -5px;
                position: absolute;
            }
        }


    }

}

.text-lg {
    line-break: anywhere;
}