#root {
    max-width: 1800px;
    margin: 0 auto;

    .public {
        height: calc(100vh - 64px);
    }

    .nav-logo {
        max-height: 42px;
    }
}

html[data-theme='dark'] {
    .nav-logo {
        display: none;

        &.darkbg {
            display: block;
        }
    }
}

html[data-theme='light'] {
    .nav-logo {
        display: block;

        &.darkbg {
            display: none;
        }
    }
}